.container {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  height: 4.5rem;
  width: 30rem;
  border-radius: 5rem;
  padding: 0 2rem 0 1rem;
  border: 1px solid #78a2a2;
  transition: all 0.15s ease-in-out;
}

.container.full {
  width: 100%;
}

.container > svg {
  flex-shrink: 0;
  width: 3rem;
  height: 3rem;
  color: #78a2a2;
}

.container > input {
  flex: 1;
  height: 100%;
  min-width: 0;
  border: none;
  background: none;
  outline: none;
  color: #78a2a2;
}

.container > input::placeholder {
  color: #78a2a2;
}

.container:focus-within,
.container:hover {
  border: 1px solid #a5c0c0;
}

.container:focus-within > input,
.container:hover > input {
  color: #a5c0c0;
}

.container:focus-within > svg,
.container:hover > svg {
  color: #a5c0c0;
}

.container:focus-within > input::placeholder,
.container:hover > input::placeholder {
  color: #a5c0c0;
}
