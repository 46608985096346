/* case-sharing-request */
.case_sharing_request_row {
  display: grid;
  grid-template-columns: 12.5% 12.5% 15% 10% 12.5% 15% 10% 7.5% 5%;
  border-top: 1px solid #456060;
  padding: 1rem 0;
  font-family: 'Noto Sans', 'Gill Sans', Helvetica, sans-serif;
  font-size: 1.75rem;

  transition: 120ms all ease;
}

.case_sharing_request_row div {
  max-height: 40rem;
  overflow-y: auto;

  padding: auto 0;
}

.case_sharing_request_row:hover {
  background-color: rgb(37, 56, 56);
  color: rgb(40, 243, 189);
}

.accept {
  cursor: pointer;
  font-weight: 1.5rem;
  text-transform: uppercase;
  min-width: 5rem;
  font-size: 1.5rem;
  text-align: center;
  border: 0.1rem solid #fff;
  border-radius: 0.7rem;
  background: transparent;
  color: white;
  transition-duration: 0.3s;
}

.accept:hover {
  background-color: green;
}

.reject {
  background-color: white;
  color: black;
  border: none;
  border-radius: 50%;
  height: 4rem;
  line-height: 4rem;
  width: 4rem;
  font-size: 4rem;
  margin-left: 2rem;
  text-align: center;
}

.reject:hover {
  background-color: red;
}

/* end of case-sharing-request */