:root {
  --bg: #242526;
  --bg-accent: #484a4d;
  --text-color: #dadce1;
  --nav-size: 60px;
  --border: none;
  --border-radius: 8px;
  --speed: 500ms;
}

.menu_item {
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: var(--border-radius);
  transition: background var(--speed);
  padding: 0.5rem;
  border: none;
  color: white;
  background-color: #223131;
  margin-bottom: 5px;
}

.menu_item:hover {
  /* background-color: cornflowerblue; */
  background-color: #378264;
}

.icon_item {
  --button-size: calc(var(--nav-size) * 0.5);
  width: var(--button-size);
  height: var(--button-size);
  border-radius: 50%;
  padding: 5px;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: filter 300ms;
}

.icon_item:hover {
  filter: brightness(1.2);
}

.icon_item svg {
  fill: var(--text-color);
  width: 24px;
  height: 24px;
}
