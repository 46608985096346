.discipline {
  background: url('./discipline_background.png');
  width: 18rem;
  height: 23rem;
  background-repeat: no-repeat;
  font-weight: normal;
  font-family: 'Noto Sans', sans-serif;
  font-style: normal;
  color: #f7f7f7;
  text-align: center;
  position: relative;
  margin-bottom: 2rem;
  margin-left: 3rem;

  -webkit-transition: all ease 0.3s;
  -moz-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  -ms-transition: all ease 0.3s;
  transition: all ease 0.3s;
  transition-duration: 0.3s;
}

.discipline:hover {
  background: url('./discipline_background_hover.png');
}

.discipline .discipline-header {
  padding-top: 1rem;
}

.discipline .discipline-header p {
  margin-right: auto;
  margin-left: auto;
  opacity: 0.397;
  overflow: visible;
  font-size: 1.7rem;
  font-weight: 900;
  color: #456060;
}

.discipline:hover .discipline-header p {
  color: #b5f7ed;
}

.discipline .discipline-body p {
  margin-top: 5rem;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  font-size: 1.8rem;
  font-family: 'Noto Sans', sans-serif;
  font-style: normal;
  font-weight: 900;
}
