.container {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.empty-discipline-warning {
  flex: 1;
  width: 37rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.2;
  color: #fff;
  font-family: 'Noto Sans', sans-serif;
  font-weight: 800;
  font-size: 4rem;
  text-align: center;
}
