.container {
  margin-bottom: 5rem;
}

.container > *:not(.image_container) {
  padding-left: 7rem;
  padding-right: 7rem;
}

.container > .image_container {
  position: relative;
}

.container > .image_container > img {
  width: 100%;
  height: 40rem;
  object-fit: cover;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
}
.container > .image_container::before {
  content: '';
  position: absolute;
  top: 0;
  width: 100%;
  height: 40rem;
  background: rgba(34, 49, 49, 1);
  background: linear-gradient(
    0deg,
    rgba(34, 49, 49, 1) 0%,
    rgba(0, 0, 0, 0) 56%
  );
  z-index: 1;
}

.container h3 {
  margin-top: 3rem;
  font-weight: 600;
  font-size: 2.7rem;
  text-transform: uppercase;
  line-height: 1;
}

.info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 2rem;
}

@media (max-width: 1230px) {
  .info {
    flex-direction: column;
    gap: 1.5rem;
  }
}

.details {
  display: flex;
  column-gap: 7rem;
}

.details > span {
  display: flex;
  align-items: center;
}

.button_group {
  display: flex;
  column-gap: 2rem;
}

.button_group a {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 1.5rem;
  width: 18.5rem;
  background-color: #348d66;
  border: none;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  height: 5rem;
  border-radius: 0.7rem;
  font-family: 'Noto Sans', sans-serif;
  font-size: 1.6rem;
  color: #fff;
  transition: all 0.4s ease;
}

.button_group a:hover {
  color: rgb(52, 74, 74);
  background-color: rgb(40, 243, 189);
  box-shadow: 0.5rem 0.5rem 1rem rgba(40, 243, 189, 0.2);
}

.button_group button {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 1.5rem;
  width: 18.5rem;
  background-color: transparent;
  outline: none;
  border: 0.1rem solid #fff;
  cursor: pointer;
  height: 5rem;
  border-radius: 0.7rem;
  font-family: 'Noto Sans', sans-serif;
  font-size: 1.6rem;
  color: #fff;
  transition: all 0.4s ease;
}

.button_group .active {
  border: 0.1rem solid #28f3bd;
  color: #28f3bd;
}
