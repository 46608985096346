.loading_bar {
  height: 100%;
  width: 50%;
  background-color: #ff3737;
  position: absolute;
  left: -50%;
  animation: loading 2s ease-in 0.5s infinite;
}

.loading_bar_container {
  height: 2px;
  width: 100%;
  background-color: #44b483;
  position: absolute;
  overflow: hidden;
}

@keyframes loading {
  0% {
    transform: translateX(0);
  }
  to {
    transform: translateX(400%);
  }
}
