/* Start of NotificationItem style */

.notification_item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  position: relative;
  border-radius: 5px;
  background-color: #48b583;
  color: #fff;
  font-size: 1.27rem;
  font-weight: bold;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  cursor: pointer;
  text-align: left;

  border: none;
  outline: none;

  padding: 1rem 2rem 1rem 0.5rem;

  overflow: visible;

  transition: filter 200ms ease;
}

.not_read::after {
  position: absolute;
  content: '';
  top: 50%;
  left: -1.3rem;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  transform: translateY(-50%);
  background: #3cc8f4;
}

.notification_item:hover {
  filter: drop-shadow(4px 6px 6px rgba(0, 0, 0, 0.5));
}

.notification_item > svg {
  min-width: 2.8rem;
  min-height: 2.8rem;
  width: 2.8rem;
  height: 2.8rem;
}

.notification_item > svg > * {
  width: 2.8rem;
  height: 2.8rem;
  color: white;
}

.notification_item button {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  outline: none;
  border: none;
  margin: 0.3rem;
  cursor: pointer;
}

.notification_item > button > svg {
  width: 1.8rem;
  height: 1.8rem;
  color: white;
}

.blue {
  background-color: #3a48ca;
}

.green {
  background-color: #48b583;
}

.red {
  background-color: #ff3737;
}

.yellow {
  background-color: #ffb200;
}

.gray {
  background-color: #4c5e64;
}

/* End of NotificationItem style */
