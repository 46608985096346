.left_sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background-color: #1e2d2e;
  width: 7.6rem;
  height: 100%;
  overflow-x: hidden;
}

.left_sidebar.sidebar_is_open {
  width: 26.6rem;
}

.left_sidebar_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 1;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.wikki_logo_below {
  width: 100%;
  padding: 1.5rem;
}
