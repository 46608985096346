/* case-sharing-requests-page */
.case_sharing_requests_page {
  margin: 2rem 5rem;
}
/* end of case-sharing-requests-page */

/* header */
.header {
  display: flex;
  margin-bottom: 3rem;
}

.header svg {
  margin: 0rem 2rem;
}

.header h3 {
  font-size: 2.8rem;
}

.header p {
  font-size: 1.8rem;
  color: #78a2a2;
}
/* end of header */

/* body */
.body {
  margin-top: 1rem;
}
/* end of body */
