.container {
  padding-left: 7rem;
  padding-right: 7rem;
}

.container > h3 {
  text-transform: uppercase;
  font-size: 1.8rem;
  font-weight: 600;
  letter-spacing: 0.235rem;
  margin-bottom: 2rem;
}

.container > div:not(:last-child) {
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

.rating {
  display: flex;
  align-items: center;
  font-size: 7rem;
}

.rating > .stars {
  display: flex;
  column-gap: 0.5rem;
}

.rating > .stars > button {
  width: 3.5rem;
  height: 3.5rem;
  background: transparent;
  border: none;
  cursor: pointer;
}

.rating > div > button > svg {
  width: 100%;
  height: 100%;
}

.rating > .value {
  width: 5rem;
}

.select_container {
  position: relative;
  width: fit-content;
}

.select_container > svg {
  pointer-events: none;
  position: absolute;
  width: 2.5rem;
  height: 2.5rem;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  color: #28f3bd;
}

.select_container > select {
  appearance: none;
  display: block;
  background-color: #1f2d2e;
  border: 0.1rem solid #44b483;
  border-radius: 0.7rem;
  height: 5.6rem;
  width: 35rem;
  padding-left: 2rem;
  padding-right: 3rem;
  color: #44b483;
  font-size: 1.8rem;
  cursor: pointer;
}

.select_container > select:hover {
  background-color: #2a3e3f;
}

.container > button {
  width: 18.5rem;
  background-color: #348d66;
  border: none;
  outline: none;
  cursor: pointer;
  height: 5rem;
  border-radius: 0.7rem;
  font-family: 'Noto Sans', sans-serif;
  font-size: 1.6rem;
  color: #fff;
  transition: all 0.4s ease;
}

.container > button:hover {
  color: rgb(52, 74, 74);
  background-color: rgb(40, 243, 189);
  box-shadow: 0.5rem 0.5rem 1rem rgba(40, 243, 189, 0.2);
}

.opinion {
  display: block;
  background-color: rgba(31, 45, 46, 1);
  padding: 1.2rem 2rem;
  color: rgba(68, 180, 131, 1);
  font-family: 'Noto Sans', 'Gill Sans', Helvetica, sans-serif;
  border: 0.1rem solid rgba(68, 180, 131, 1);
  border-radius: 0.7rem;
  outline: none;
  resize: none;
  height: 20rem;
  width: 70rem;
}

.opinion::placeholder {
  color: rgba(68, 180, 131, 1);
  opacity: 0.6;
}

.opinion:hover {
  border: 0.1rem solid rgb(80, 228, 164);
}

.comments_container {
  margin-top: 2rem;
  position: relative;
  width: 100%;
}

.comments_tabs {
  display: flex;
}

.comments_tabs button {
  flex-grow: 1;
  height: 6rem;
  font-size: 2rem;
  background-color: transparent;
  border: unset;
  border-bottom: 0.2rem solid #344a4a;
  color: #b5f7ed;
  cursor: pointer;
}

.comments_tabs button.selected {
  border-bottom: 0.2rem solid white;
  color: white;
}

.comment_list {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  padding-top: 2.5rem;
  padding-bottom: 2rem;
}

.comment_rating {
  margin-bottom: 1rem;
}

.comment_content {
  display: flex;
  justify-content: space-between;
  column-gap: 1rem;
  min-height: 10rem;
}

.comment_content > *:not(:last-child) {
  flex-grow: 1;
}

.comment_info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.comment_info :nth-child(1) {
  font-size: 2.2rem;
}
.comment_info :nth-child(2) {
  font-size: 2rem;
}

.comment_date {
  letter-spacing: 0.2rem;
  opacity: 0.7;
}

.comment_actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0.5rem;
  color: #b5f7ed;
  font-size: 1.6rem;
}

.comment_actions > button {
  width: 2.5rem;
  height: 2.5rem;
  background-color: transparent;
  border: none;
  color: #456060;
  cursor: pointer;
}
.comment_actions > button:hover {
  color: #28f3bd;
}

.comment_actions > button > svg {
  width: 100%;
  height: 100%;
}

.empty_comment_list {
  color: #b5f7ed;
  font-size: 1.8rem;
}

button.user_liked,
button.user_disliked {
  color: #28f3bd;
}
