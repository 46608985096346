.sign-in-form {
  position: relative;
  width: 51.3rem;
  height: auto;
  text-align: center;
  border-radius: 0.7rem;
  box-shadow: 0.5rem 0.5rem 0.5rem rgba(0, 0, 0, 0.4);
  background-color: rgba(31, 45, 46, 1);
  user-select: none;
  z-index: 10;
}

.form-signin {
  padding: 3.5rem;
}

.sign-in-form-title {
  width: fit-content;
  margin: 0 auto;
  font-weight: normal;
  font-size: 2.4rem;
  color: rgba(181, 247, 237, 1);
}

.sign-in-form-body {
  display: flex;
  flex-direction: column;
  width: 35.4rem;

  margin: 1rem auto;
}

.text-input {
  background-color: rgba(31, 45, 46, 1);
  overflow: visible;
  width: 35.4rem;
  height: 5.6rem;
  padding: 1.2rem 2rem;
  margin: 1.6rem 0;
  color: rgba(68, 180, 131, 1);
  font-family: 'Noto Sans', 'Gill Sans', Helvetica, sans-serif;
  border: 1px solid rgba(68, 180, 131, 1);
  border-radius: 7px;
  box-sizing: border-box;
}

.password-input {
  padding-right: 4rem;
  padding-left: 4rem;
}

.text-input:hover {
  border: 1px solid rgb(80, 228, 164);
}

.text-input:focus {
  border: 1px solid rgb(80, 228, 164);
  outline: none;
}

.text-input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(68, 180, 131, 1);
  opacity: 0.6; /* Firefox */
}

.text-input-error,
.text-input-error:hover,
.text-input-error:focus {
  border-color: #ff3737;
}

.sign-in-form-error-message {
  width: 100%;
  margin: 0.5rem 0 1.5rem;
  color: #ff3737;
}

.submit-buttom {
  background-color: rgba(52, 141, 102, 1);
  border: none;
  cursor: pointer;
  width: 25.5rem;
  height: 5rem;
  border-radius: 7px;
  font-family: 'Noto Sans', sans-serif;
  font-size: 1.6rem;
  color: white;
  overflow: hidden;
  margin: 0 auto;
  outline: none;

  -webkit-transition: all ease 0.4s;
  -moz-transition: all ease 0.4s;
  -o-transition: all ease 0.4s;
  -ms-transition: all ease 0.4s;
  transition: all ease 0.4s;
}

.submit-buttom:hover {
  color: rgb(52, 74, 74);
  background-color: rgb(40, 243, 189);
  box-shadow: 0.5rem 0.5rem 1rem rgba(40, 243, 189, 0.2);
}

.sign-in-form-password-container {
  position: relative;
}

.sign-in-form-password-lock {
  position: absolute;
  top: 40%;
  left: 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
}

.sign-in-form-password-eye {
  position: absolute;
  top: 40%;
  right: 1.5rem;
  width: 2.5rem;
  height: 2rem;
  cursor: pointer;

  background: none;
  text-decoration: none;
  outline: none;
  border: none;
}

.sign-in-form-icon {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
