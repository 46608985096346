/* dropdown menu */
.user_dropdown_menu {
  position: absolute;
  top: 58px;
  width: 300px;
  transform: translateX(-45%);
  background-color: #1e2d2e;
  border: 1px solid #474a4d;
  border-radius: 8px;
  padding: 1rem;
  overflow: hidden;
}
.user_dropdown_menu h4 {
  padding-bottom: 8px;
}
/* end of dropdown menu */
