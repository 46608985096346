/* notifications */

.notifications {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 0 1.5rem;
  
  max-height: calc(100% - 75px);
  overflow-y: auto;
}

.notifications::-webkit-scrollbar {
  width: 10px;
  height: 15px;
}

/* end of notifications */
