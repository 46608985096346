.modal {
  display: grid;
  place-items: center;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.8);
}
.form_modal {
  padding: 3rem 5rem;
}

.edit_modal {
  width: 50rem;

  background-color: #182222;

  display: flex;
  flex-direction: column;
  position: relative;
}

.close_icon {
  position: absolute;
  top: 0;
  right: 0;

  margin: 1.5rem;
  cursor: pointer;
  outline: none;
  border: none;
  background: none;
}

.edit_header {
  margin-bottom: 1.5rem;
}

.edit_header h1 {
  font-size: 2.4rem;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
}

.edit_header p {
  font-size: 1.6rem;
  text-align: center;
}

.edit_header span {
  font-weight: bold;
  cursor: default;
}

.edit_modal textarea {
  resize: none;
  height: 15rem;
}

.input_label {
  font-size: 1.6rem;
  margin: 1rem 0;
}

.text_input {
  background-color: rgba(31, 45, 46, 1);
  width: 100%;
  height: 5.6rem;
  padding: 1.2rem 2rem;
  color: rgba(68, 180, 131, 1);
  border: 1px solid rgba(68, 180, 131, 1);
  border-radius: 7px;

  outline: none;
}

.text_input:hover {
  border: 1px solid rgb(80, 228, 164);
}

.text_input:focus {
  border: 1px solid rgb(80, 228, 164);
  outline: none;
}

.text_input_error,
.text_input_error:hover,
.text_input_error:focus {
  border-color: #ff3737;
}

.update_errors {
  color: #ff3737;
  text-align: center;
  margin: 0.5rem 0;
}

.update_errors:first-of-type {
  margin: 2rem 0;
}

.buttons_wrapper {
  display: flex;
  justify-content: space-between;

  margin-top: 1rem;
}

.buttons_wrapper button[type='submit'] {
  background-color: rgba(52, 141, 102, 1);
  border: none;
  cursor: pointer;
  width: 40%;
  height: 5rem;
  border-radius: 7px;
  font-family: 'Noto Sans', sans-serif;
  font-size: 1.6rem;
  color: white;
  overflow: hidden;
  margin: 0 auto;
  outline: none;

  -webkit-transition: all ease 0.4s;
  -moz-transition: all ease 0.4s;
  -o-transition: all ease 0.4s;
  -ms-transition: all ease 0.4s;
  transition: all ease 0.4s;
}

.buttons_wrapper button[type='submit']:hover:enabled {
  color: rgb(52, 74, 74);
  background-color: rgb(40, 243, 189);
  box-shadow: 0.5rem 0.5rem 1rem rgba(40, 243, 189, 0.2);
}

.buttons_wrapper button[type='submit']:disabled {
  background-color: rgba(52, 141, 102, 0.2);
  cursor: auto;
}

.buttons_wrapper button[type='button'] {
  background-color: transparent;
  border: 1px solid #fff;
  cursor: pointer;
  width: 40%;
  height: 5rem;
  border-radius: 7px;
  font-family: 'Noto Sans', sans-serif;
  font-size: 1.6rem;
  color: white;
  overflow: hidden;
  margin: 0 auto;
  outline: none;

  -webkit-transition: all ease 0.4s;
  -moz-transition: all ease 0.4s;
  -o-transition: all ease 0.4s;
  -ms-transition: all ease 0.4s;
  transition: all ease 0.4s;
}

.buttons_wrapper button[type='button']:hover {
  box-shadow: 0.5rem 0.5rem 1rem rgba(40, 243, 189, 0.2);
}
