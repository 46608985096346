/* case-sharing-request-list */
.case_sharing_request_list {
  margin-top: 4rem;
}

/* end of case-sharing-request-list */

/* header */
.header {
  display: grid;
  grid-template-columns: 12.5% 12.5% 15% 10% 12.5% 15% 10% 7.5% 5%;
  font-size: 1.8rem;
  color: #b5f7ed;
  width: 100%;
}

/* end of header */

/* body */
.body {
  padding-bottom: 2rem;
}

/* end of body */