.left-nav-panel {
  background-color: #1e2d2e;
  width: 100%;
  max-width: 6.2rem;
  padding: 0.5rem;
}

.left-nav-panel.is-open {
  min-width: 17.5rem;
  max-width: 33rem;
  width: 25rem;
}

.item {
  background-color: #223131;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-left: solid 0.3rem #182222;
  padding: 0.8rem 0.8rem;
  margin-bottom: 1rem;
  height: 4rem;
  width: 100%;

  font-family: 'Noto Sans', sans-serif;
  font-size: 1.4rem;
  font-weight: normal;
  color: #b5f7ed;
  text-decoration: none;
  outline: none;
  border: none;

  cursor: pointer;
}

.item:hover,
.item.current.on_categories,
.current {
  cursor: pointer;
  border-left: solid 0.3rem #28f5be;
  color: #28f5be;
}

.separator {
  margin: 2.5rem 0 2.2rem 4rem;
  text-align: left;
  font-family: Proxima Nova, sans-serif;
  font-size: 1.2rem;
  color: #78a2a2;
  text-transform: uppercase;
}

.left-nav-panel:not(.is-open) .separator {
  margin: 2.5rem 0 2.2rem 0;
  border-bottom: solid 0.2rem #78a2a2;
}
