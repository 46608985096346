.projects {
  background-color: #192222;
  min-height: calc(100vh - 7rem);
  padding: 0 2rem;
}

.project-list-container {
  width: 100%;
  min-width: 68rem;
  background-color: inherit;
}

.project_header {
  display: flex;
  flex-direction: row;

  width: 100%;

  padding: 3rem 4.5rem;
}

.project_header div:first-child {
  display: flex;
  flex-direction: row;
  width: 50%;
}

.project_header div:first-child img {
  height: 100%;
}

.heading_title {
  display: flex;
  flex-direction: column;
  margin-left: 2.5rem;
}

.heading_title h1 {
  font-size: 3.6rem;
  height: 4.7rem;
  font-weight: 400;
  margin-top: auto;
}

.heading_title p {
  font-weight: 400;
  height: 2.4rem;
  font-size: 2.4rem;
  text-transform: uppercase;
  color: #78a2a2;
}

.search {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  width: 50%;
}
