.rating {
  display: flex;
  align-items: center;
  column-gap: 0.1rem;
}

.rating svg {
  height: 1.6rem;
  width: 1.6rem;
}
