.modal {
  display: grid;
  place-items: center;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.8);
}

.remove_modal {
  display: flex;
  flex-direction: column;

  background-color: #182222;

  width: 75rem;

  padding: 3rem 2.5rem;
}

.remove_header {
  display: flex;
  flex-direction: row;
}

.remove_header > svg {
  height: 15rem;
  width: 15rem;
  color: white;
}

.remove_heading {
  padding-left: 3rem;
}

.remove_heading h1 {
  font-size: 2.4rem;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
}

.remove_heading p:last-of-type {
  margin-top: 1.5rem;
}

.remove_heading span {
  font-weight: 600;
}

.remove_heading input[type='text'] {
  background-color: rgba(31, 45, 46, 1);
  width: 100%;
  height: 3.6rem;
  padding: 0.5rem 1.5rem;
  font-size: inherit;
  color: rgba(68, 180, 131, 1);
  border: 1px solid rgba(68, 180, 131, 1);
  border-radius: 7px;

  outline: none;
}

.remove_heading input[type='text']:hover {
  border: 1px solid rgb(80, 228, 164);
}

.remove_heading input[type='text']:focus {
  border: 1px solid rgb(80, 228, 164);
  outline: none;
  box-shadow: 0 0 0.5rem rgb(80, 228, 164);
}

.delete_buttons {
  margin-top: 2rem;
  text-align: end;
}

.delete_buttons button:first-child {
  margin-right: 3rem;
}

.delete_buttons button[type='submit'] {
  background-color: rgba(52, 141, 102, 1);
  border: none;
  cursor: pointer;
  width: 20%;
  height: 5rem;
  border-radius: 7px;
  font-family: 'Noto Sans', sans-serif;
  font-size: 1.6rem;
  color: white;
  overflow: hidden;
  outline: none;

  -webkit-transition: all ease 0.4s;
  -moz-transition: all ease 0.4s;
  -o-transition: all ease 0.4s;
  -ms-transition: all ease 0.4s;
  transition: all ease 0.4s;
}

.delete_buttons button[type='submit']:disabled {
  background-color: rgba(52, 141, 102, 0.2);
  cursor: auto;
}

.delete_buttons button[type='button'] {
  background-color: transparent;
  border: 1px solid #fff;
  cursor: pointer;
  width: 30%;
  height: 5rem;
  border-radius: 7px;
  font-family: 'Noto Sans', sans-serif;
  font-size: 1.6rem;
  color: white;
  overflow: hidden;
  outline: none;

  -webkit-transition: all ease 0.4s;
  -moz-transition: all ease 0.4s;
  -o-transition: all ease 0.4s;
  -ms-transition: all ease 0.4s;
  transition: all ease 0.4s;
}

.delete_buttons button[type='button']:hover {
  box-shadow: 0.5rem 0.5rem 1rem rgba(40, 243, 189, 0.2);
}
