.sectionTestimonials {
  height: 100vh;
  min-height: 72rem;
  padding-top: 20vh;
}

.wrapper {
  margin-bottom: 5%;
  text-align: center;
}

.title {
  line-height: 4.8rem;
  margin-top: -.33rem;
  font-weight: 900;
  font-size: 4.2rem;
  color: rgba(39, 106, 77, 1);
  letter-spacing: -0.04px;
}

.subtitle {
  white-space: nowrap;
  font-weight: bold;
  font-size: 1.4rem;
  color: rgb(84, 116, 116);
  letter-spacing: .223rem;
}
