.section-info {
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  max-height: 100vh;
  min-height: 72rem;
  display: flex;
  align-items: center;
}

.spacer {
  flex: 1;
}

.info {
  flex: 1;
  height: 60%;
  text-align: right;
  position: relative;
  margin-right: 1.8rem;
  max-width: 85rem;
}

.description-box {
  position: absolute;
  display: inline-block;
  top: 11%;
  left: -70%;
  width: 100%;
  min-width: 75rem;
  max-width: 80rem;
  min-height: 60%;
  z-index: 2;
  background: #1f2d2e;
  border-radius: 0.6rem;
  text-align: left;
  font-family: 'Noto Sans', sans-serif;
}

.header {
  display: flex;
  align-items: center;
  padding-top: 2.5rem;
}

.title {
  flex: 1;
  margin-left: 6rem;
  font-size: 3rem;
  color: white;
  font-weight: bold;
}

.subtitle {
  flex: 1;
  text-align: right;
  margin-right: 6rem;
  font-size: 4.8rem;
  font-weight: bold;
  color: #182222;
  opacity: 85%;
}

hr {
  background: linear-gradient(to left, rgba(68, 180, 131, 1), transparent);
  height: 1px;
  border: 0;
  margin: 0;
}

.description {
  margin: 2rem 6rem;
  color: white;
  font-weight: normal;
  text-align: justify;
}

.image-border {
  padding: 0;
  display: inline-block;
  width: 100%;
  height: 100%;
  margin-right: 1.8rem;
  border: 1px solid #276a4d;
  opacity: 0.5;
}

.info:hover .image-border {
  border: 1px solid rgba(68, 180, 131, 1);
}

.section-info img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: -1.7rem;
  right: -1.7rem;
}

.info:hover img {
  -webkit-filter: brightness(104%);
  filter: brightness(104%);
}
