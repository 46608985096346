.header {
  display: grid;
  grid-column: repeat(5, 1fr);
  grid-template-areas: 'menu case-name logoff-container';

  width: 100vw;
  height: 5rem;

  padding: 0 4rem 0 2.5rem;

  background-color: #223131;

  position: relative;
}

.menu-button {
  grid-area: menu;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 0.5rem;
  height: fit-content;
  width: 3.5rem;
  padding: 0.25rem;
}

.menu-button > svg {
  color: white;
  opacity: 0.9;
}

.menu-button:hover > svg {
  opacity: 1;
}

.header-menu-container {
  grid-area: menu;
  display: flex;
  align-items: center;
  width: 25rem;
}

.logo-container {
  display: flex;
  align-items: center;
  margin-left: 2rem;
}

.logo {
  height: 2.5rem;
}

.header-case-name-container {
  grid-area: case-name;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: default;
}

.header-logoff-container {
  grid-area: logoff-container;
  display: flex;
  align-items: center;
}

/* header item nav */
.header_item {
  max-width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}
/* end header item nav */
