.card {
  display: block;
  position: relative;
  width: 35rem;
  height: 22rem;
  border-radius: 0.6rem;
  box-sizing: border-box;
  background-color: rgb(34, 49, 49);
  color: white;
  font-family: 'Noto Sans', sans-serif;
  text-decoration: none;
  transition: all 0.2s ease-in-out 0.05s;
}

.expanded {
  border-radius: rem;
  border: 0.4rem solid white;
}

.expanded::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-top: 1rem solid white;
  border-left: 2rem solid transparent;
  border-right: 2rem solid transparent;
  z-index: 1;
}

.image {
  position: relative;
  width: 100%;
  height: 50%;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(34, 49, 49, 1);
  background: linear-gradient(
    0deg,
    rgba(34, 49, 49, 0.7) 0%,
    rgba(0, 0, 0, 0) 10%
  );
}
.content {
  box-sizing: border-box;
  height: 50%;
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem 2rem;
  row-gap: 0.8rem;
}

.details {
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
}

.name {
  font-size: 2rem;
  font-weight: 600;
  text-transform: uppercase;
}

.rating {
  display: flex;
  align-items: center;
  column-gap: 0.4rem;
  font-size: 1.1rem;
}

.info {
  font-size: 1.2rem;
}

.description {
  display: none;
}

.expand-button {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 1.8rem;
  bottom: 0.5rem;
  left: 0;
  background-color: unset;
  outline: none;
  border: none;
  padding: 0;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.expand-button svg {
  width: 2rem;
}

.card:not(.expanded):hover {
  z-index: 1;
  transform: scale(1.3);
  box-shadow: 0 0.4rem 0.6rem -0.1rem rgba(0, 0, 0, 0.1),
    0 0.2rem 0.4rem -0.1rem rgba(0, 0, 0, 0.06);
}

.card:not(.expanded):hover .details {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.card:not(.expanded):hover .name {
  font-size: 1.8rem;
}

.card:not(.expanded):hover .info {
  display: none;
}

.card:not(.expanded):hover .comments {
  display: none;
}

.card:not(.expanded):hover .description {
  width: 100%;
  font-size: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

.card:not(.expanded):hover .expand-button {
  cursor: pointer;
  opacity: 1;
}
