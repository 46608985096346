.left-main-panel {
  background-color: #1e2d2e;
  max-width: 6.2rem;
  padding: 0.5rem;
  overflow-x: hidden;
}

.left-main-panel.is-open {
  min-width: 17.5rem;
  max-width: 33rem;
  width: 25rem;
}

.item {
  display: flex;
  background-color: #182222;
  width: 100%;
  height: 5rem;
  margin-bottom: 1rem;
  padding: 0.8rem 0.9rem;
  border-left: solid 0.3rem #182222;
  box-sizing: border-box;
  justify-content: initial;
  align-items: center;
  font-family: 'Noto Sans', sans-serif;
  font-size: 1.4rem;
  font-weight: normal;
  color: #b5f7ed;
  text-decoration: none;
  outline: none;
}

.active-item {
  border-left: solid 0.3rem #28f5be;
  color: #28f5be;
}

.item:hover {
  cursor: pointer;
  border-left: solid 0.3rem #28f5be;
  color: #28f5be;
}

.icon {
  max-width: 3rem;
  max-height: 3rem;
}

.icon ~ p {
  margin-left: 2rem;
}

.name {
  height: fit-content;
  margin-top: auto;
  margin-bottom: auto;
}
