.danger {
  background-color: rgb(248, 215, 218);
  border-color: rgb(245, 198, 203);
  color: #ff3737;
}

.info {
  background-color: rgb(209, 236, 241);
  border-color: rgb(190, 229, 235);
  color: #3b37ff;
}

.message {
  display: flex;
  flex-direction: row;
  height: 4rem;
  justify-content: space-between;
  align-items: center;
  border-style: solid;
  border-width: 0.2rem;
  margin: 5px;
  border-radius: 0.4rem;
}

.message button {
  background-color: transparent;
  width: 3rem;
  height: 3rem;
  margin-right: 0.7rem;
  border-radius: 50%;
  border-style: none;
  font-size: 2rem;
}

.message button:hover {
  color: red;
}

.message p {
  width: 100%;
  text-align: center;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 2rem;
}

.success {
  background-color: rgb(212, 237, 218);
  border-color: rgb(195, 230, 203);
  color: rgb(21, 87, 36);
}

.warning {
  background-color: rgb(255, 243, 205);
  border-color: rgb(255, 238, 186);
  color: rgb(133, 100, 4);
}
