@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;300;400;500;700;900&display=swap');

:root {
  --dark-slate-gray: #344a4a;
  --white: #fcfcfc;
  --dark-jungle-green: #192223;
  --charleston-green: #1e2d2e;
  --outer-space-crayola: #283738;
  --outer-space-crayola-secundary: #2a3e3f;
  --medium-aquamarine: #50e4a4;
  --mint: #44b483;
  --celeste: #b5f7ed;
  --error-red: #ff3737;
}

html {
  font-size: 62.5%;
  word-break: break-word;
}

::-webkit-scrollbar-thumb {
  height: 30px;
  background-color: hsl(180, 18%, 16%);
}

::-webkit-scrollbar-track-piece {
  background-color: rgba(66, 82, 82, 0.5);
}

::-webkit-scrollbar {
  width: 15px;
  height: 15px;
}

body {
  font-family: 'Noto Sans', sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.5;
  color: white;
  background-color: #182222;
  box-sizing: border-box;
  overflow-x: hidden;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  print-color-adjust: exact;
  -webkit-print-color-adjust: exact;
}

input,
textarea,
select,
code {
  font-family: inherit;
}

@media print {
  header,
  aside,
  nav {
    display: none;
  }
}
