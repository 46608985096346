.container {
  display: flex;
  padding-left: 13.2rem;
  position: relative;
  color: white;
}

.content {
  padding: 5rem 0 3rem;
  width: 50%;
}

.has_buttons {
  padding: 7rem 0 0;
}

.category {
  letter-spacing: 0.357rem;
}

.name {
  font-size: 3.2rem;
  font-weight: 700;
  letter-spacing: -0.03px;
  text-transform: uppercase;
}

.details {
  display: flex;
  column-gap: 4.5rem;
  align-items: center;
}

.description {
  height: 19.2rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  overflow-y: auto;
  text-overflow: ellipsis;
  padding: 0.5rem 1.5rem 0.5rem 0;
}

.button-group {
  padding: 2rem 0 4rem;
  display: flex;
  column-gap: 3rem;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18.5rem;
  background-color: #348d66;
  border: none;
  outline: none;
  cursor: pointer;
  height: 5rem;
  border-radius: 0.7rem;
  font-family: 'Noto Sans', sans-serif;
  font-size: 1.6rem;
  color: #fff;
  text-decoration: none;
  transition: all 0.4s ease;
}

.button:hover {
  color: rgb(52, 74, 74);
  background-color: rgb(40, 243, 189);
  box-shadow: 0.5rem 0.5rem 1rem rgba(40, 243, 189, 0.2);
}

.toggle-button {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 1.5rem;
  width: 18.5rem;
  background-color: transparent;
  outline: none;
  border: 1px solid #fff;
  cursor: pointer;
  height: 5rem;
  border-radius: 0.7rem;
  font-family: 'Noto Sans', sans-serif;
  font-size: 1.6rem;
  color: #fff;
  transition: all 0.4s ease;
}

.toggle-button-active {
  border: 1px solid #28f3bd;
  color: #28f3bd;
}
