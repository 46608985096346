.container {
  width: min(65rem, 60%);
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.form {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  width: 100%;
}

.form div {
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
}

.form label {
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
  font-size: 1.6rem;
}

.form label input {
  background-color: rgba(31, 45, 46, 1);
  height: 5.6rem;
  padding: 1.2rem 2rem;
  font-family: 'Noto Sans', 'Gill Sans', Helvetica, sans-serif;  
  outline: none;
  border: 0.1rem solid rgb(80, 228, 164);
  border-radius: 0.7rem;
  color: rgba(68, 180, 131, 1);
  opacity: 0.6;
}

.form label input::placeholder {
  color: rgba(68, 180, 131, 1);
  opacity: 0.6;
}

.form label textarea {
  background-color: rgba(31, 45, 46, 1);
  padding: 1.2rem 2rem;
  color: rgba(68, 180, 131, 1);
  font-family: 'Noto Sans', 'Gill Sans', Helvetica, sans-serif;
  border: 0.1rem solid rgba(68, 180, 131, 1);
  border-radius: 0.7rem;
  outline: none;
  resize: none;
  height: 20rem;
}

.form label textarea::placeholder {
  color: rgba(68, 180, 131, 1);
  opacity: 0.6;
}

.form label textarea:hover {
  border: 0.1rem solid rgb(80, 228, 164);
}

.form button[type='submit'] {
  width: 100%;
  height: 5rem;
  background-color: rgba(52, 141, 102, 1);
  color: white;
  cursor: pointer;
  outline: none;

  border-radius: 0.7rem;
  border: none;
}

.form button[type='submit']:hover {
  color: rgb(52, 74, 74);
  background-color: rgb(40, 243, 189);
  box-shadow: 0.5rem 0.5rem 1rem rgba(40, 243, 189, 0.2);
}

.form button[type='submit']:disabled {
  background-color: #72cba4;
}

.error_message {
  text-align: center;
  color: #ff3737;
  font-size: 1.4rem;
}

.input_error {
  color: #ff3737;
  font-size: 1.4rem;
}
