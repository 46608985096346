.login-section {
  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  background: url('./assets/loginSectionBackground.jpg');
  background-repeat: no-repeat;
  background-size: 100vw 100vh;
}

.login-section__gradient {
  position: absolute;
  width: 100%;
  height: 30rem;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.25);
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0.25) 0%,
    rgba(0, 0, 0, 0.19) 25%,
    rgba(240, 47, 23, 0.01) 98%,
    rgba(231, 55, 39, 0) 100%
  );
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(0, 0, 0, 0.25)),
    color-stop(25%, rgba(0, 0, 0, 0.19)),
    color-stop(98%, rgba(240, 47, 23, 0.01)),
    color-stop(100%, rgba(231, 55, 39, 0))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0.25) 0%,
    rgba(0, 0, 0, 0.19) 25%,
    rgba(240, 47, 23, 0.01) 98%,
    rgba(231, 55, 39, 0) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(0, 0, 0, 0.25) 0%,
    rgba(0, 0, 0, 0.19) 25%,
    rgba(240, 47, 23, 0.01) 98%,
    rgba(231, 55, 39, 0) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(0, 0, 0, 0.25) 0%,
    rgba(0, 0, 0, 0.19) 25%,
    rgba(240, 47, 23, 0.01) 98%,
    rgba(231, 55, 39, 0) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.25) 0%,
    rgba(0, 0, 0, 0.19) 25%,
    rgba(240, 47, 23, 0.01) 98%,
    rgba(231, 55, 39, 0) 100%
  );
}

.login-section__header {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;

  padding: 4rem 5rem;
}

.header__logo {
  width: 11rem;
  height: 2.5rem;
}

.header__link-container {
  margin-left: auto;
}

.header__link {
  color: white;
  text-align: center;
  padding: 1.4rem 2rem;
  text-decoration: none;
  font-size: 1.6rem;
  letter-spacing: -0.02px;
  text-shadow: 0 0.3rem 0.6rem #b5f7ed60;
}

.header__link:hover {
  color: rgba(68, 180, 131, 1);
  cursor: pointer;
}

.login-section__footer {
  position: absolute;
  bottom: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 0 2rem 4rem;
  margin: 0;
  width: 100vw;
}

.footer__logo {
  width: 6.2rem;
  height: 6.2rem;
  border-radius: 0.5rem;
  border: 0.1rem solid rgba(31, 45, 46, 1);
  text-align: center;
  padding: 0.9rem;
}

.footer__mouse-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  text-align: center;
  width: 100%;
}

.footer__mouse-text {
  font-weight: bold;
  font-size: 1.4rem;
  color: #fff;
  letter-spacing: 0.3rem;
  margin-bottom: 1rem;
}

.footer__mouse-icon {
  height: 7rem;
  width: 2.4rem;
  margin: 0 auto;
  background-image: url('./assets/mouse.png');
  background-repeat: no-repeat;
}

.footer__mouse-icon:hover .footer__mouse-arrow-down {
  height: 2.4rem;
}

.footer__mouse-arrow-down {
  height: 1.7rem;
  width: 1.07rem;

  margin-top: 3.5rem;
  margin-left: auto;
  margin-right: auto;

  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.footer__wikki {
  height: 6rem;
  width: 35.5rem;
  padding: 2rem 3rem 2rem 1.5rem;
  color: rgba(69, 96, 96, 1);
  letter-spacing: 0.25rem;
  font-size: 1.4rem;
  border-radius: 0.5rem;
  border: 0.1rem solid #1f2d2e;
  border-right: 0;
}
