.notifications_button {
  position: relative;
  width: 40px;
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
}

.notifications_button:hover {
  background-color: rgb(47, 66, 66);
}

.notifications_button div {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
  width: 40px;
}

.notifications_button svg {
  color: white;
  width: 32px;
  height: 32px;
}

.notifications_button span {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 8px;
  right: 5px;
  width: 20px;
  height: 20px;
  border: 1px solid #233131;
  text-align: center;

  font-size: 1rem;
  white-space: nowrap;

  border-radius: 50%;
  background-color: #ff3737;
  color: #fff;
}

.new_notification_animation {
  animation: scale_and_swing 1.5s forwards ease;
}

@keyframes scale_and_swing {
  25% {
    transform: scale(1.15);
  }
  45% {
    transform: scale(1.15) rotate(10deg);
  }
  65% {
    transform: scale(1.15) rotate(-10deg);
  }
  80% {
    transform: scale(1.15) rotate(0deg);
  }
  100% {
    transform: none;
  }
}
