.cases_row {
  display: grid;
  grid-template-columns: 3rem 0.5fr 1fr 0.5fr 0.3fr 5rem 5rem;
  border-top: 1px solid #456060;
  background-color: rgb(37, 56, 56);

  padding: 0.7rem 2rem;

  color: inherit;
  text-decoration: none;
}

.cases_row div {
  max-height: 15rem;
  overflow-y: auto;
}

.cases_row > svg {
  width: 2.4rem;
  height: 2.4rem;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: inherit;
  border: none;
  outline: none;
  cursor: pointer;

  user-select: none;
}

.icon:disabled {
  opacity: 0.15;
}

.icon > svg {
  width: 2.4rem;
  height: 2.4rem;
  color: white;
}
