.project_list_container {
  width: 100%;
}

.table {
  padding: 0 3rem 0 1.5rem;

  font-size: 1.8rem;
}

.header {
  margin-top: 2rem;
  color: #b5f7ed;
}

.header p:first-child {
  grid-column-start: 2;
}

.header div {
  display: grid;
  grid-template-columns: 5.5rem 0.5fr 1fr 5rem 5rem;
}

.loading_container {
  width: 100%;
  height: 50vh;
}

.body {
  width: 100%;
  height: 100%;

  padding-bottom: 5rem;
}

.no_projects {
  width: 100%;
  height: 100%;
  padding: 2.5rem 2.5rem 1rem;
  font-weight: 600;
  font-size: 2.2rem;
  color: #78a2a2;

  border-bottom: 1px solid #78a2a2;
}
