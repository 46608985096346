.container {
  height: 100vh;
  display: grid;

  grid-template-columns: min-content 1fr;
  grid-template-rows: min-content 1fr min-content;
  grid-template-areas:
    'header header header'
    'sidebar content rightPanel';
  overflow: hidden;
}

main {
  grid-area: content;
  overflow: auto;
}

header {
  grid-area: header;
  position: sticky;
  top: 0;
  z-index: 1000;
}

nav {
  grid-area: sidebar;
  background-color: #1e2d2e;
  position: sticky;
  top: 5rem;
  height: 100%;
  overflow: hidden;
}

aside {
  grid-area: rightPanel;
  position: sticky;
  display: contents;
}
