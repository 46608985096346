/* right_panel */
.right_panel {
  position: sticky;
  top: 50px;
  height: 100%;
  max-height: calc(100vh - 50px);
  width: 30rem;
  background-color: #1f2d2e;
}

.header {
  display: flex;
  flex-direction: row;
  padding: 1.5rem 1rem;
}

.header button {
  display: flex;
  align-items: center;
  justify-content: center;

  /* margin-left: 1rem; */
  padding: 0.8rem;

  font-size: 1.5rem;
  font-weight: bold;
  color: #fff;

  background: none;
  outline: none;
  border: none;
  cursor: pointer;
}

.header button:last-of-type {
  margin-left: auto;
  padding-left: 0;
  padding-right: 0.3rem;
}

.header button:hover {
  background-color: #2a3e3f;
}

.header > button > svg {
  width: 2.4rem;
  height: 2.4rem;
  color: white;
}

.selected {
  position: relative;
}

.selected::after {
  position: absolute;
  content: '';
  width: 85%;
  height: 3px;
  background-color: #3cf4bd;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  transition: width 400ms ease-out;
}

.selected:hover:after {
  width: 100%;
}

/* end of right_panel */
