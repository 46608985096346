.userSettings {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  max-width: 45rem;
  padding-top: 3rem;
  margin: 0 auto;
}

.userSettings form {
  display: inherit;
  flex-direction: inherit;
  gap: 2rem;
}

.userSettings label {
  display: inherit;
  flex-direction: inherit;
  gap: 0.7rem;
}

.select_container {
  position: relative;
  width: 100%;
}

.select_container > svg {
  pointer-events: none;
  position: absolute;
  width: 2.5rem;
  height: 2.5rem;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  color: #28f3bd;
}

.select_container > select {
  appearance: none;
  display: block;
  background-color: #1f2d2e;
  border: 0.1rem solid #44b483;
  border-radius: 0.7rem;
  height: 5.6rem;
  width: 100%;
  padding-left: 2rem;
  padding-right: 3rem;
  color: #44b483;
  font-size: 1.8rem;
  cursor: pointer;
}

.select_container > select:hover {
  background-color: #2a3e3f;
}

.userSettings button[type='submit'] {
  background-color: rgba(52, 141, 102, 1);
  border: none;
  cursor: pointer;
  height: 5rem;
  border-radius: 7px;
  font-family: 'Noto Sans', sans-serif;
  font-size: 1.6rem;
  color: white;
  outline: none;

  -webkit-transition: all ease 0.4s;
  -moz-transition: all ease 0.4s;
  -o-transition: all ease 0.4s;
  -ms-transition: all ease 0.4s;
  transition: all ease 0.4s;
}

.userSettings button[type='submit']:hover {
  color: rgb(52, 74, 74);
  background-color: rgb(40, 243, 189);
  box-shadow: 0.5rem 0.5rem 1rem rgba(40, 243, 189, 0.2);
}
