.project_row {
  display: grid;
  grid-template-columns: 5.5rem 0.5fr 1fr 5rem 5rem;
  border-top: 1px solid #456060;
  padding: 1rem 0;

  cursor: pointer;
  transition: 120ms all ease;
}

.project_row div {
  padding: auto 0;
}

.project_row > div > svg {
  width: 2.4rem;
  height: 2.4rem;
}

.project_row span {
  border: none;
  outline: 0;
  cursor: text;
  width: 100%;

  color: inherit;
  font-size: inherit;
}

.project_row:hover {
  background-color: rgb(37, 56, 56);
  color: rgb(40, 243, 189);
}

.project_row_active {
  background-color: rgb(37, 56, 56);
  color: rgb(40, 243, 189);
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: inherit;
  border: none;
  outline: none;
  cursor: pointer;

  user-select: none;
}

.icon:disabled {
  opacity: 0.15;
}

.icon > svg {
  width: 2.4rem;
  height: 2.4rem;
  color: white;
}

.new_case {
  display: flex;
  justify-content: flex-end;
  background-color: rgb(37, 56, 56);
  border-top: 1px solid #456060;
  padding: 1rem 4rem;

  transition: 120ms all ease;
}

.new_case button {
  background-color: rgba(52, 141, 102, 1);
  border: none;
  cursor: pointer;
  padding: 1.5rem 5rem;
  border-radius: 7px;
  font-family: 'Noto Sans', sans-serif;
  font-size: 1.6rem;
  color: white;
  overflow: hidden;
  outline: none;

  -webkit-transition: all ease 0.4s;
  -moz-transition: all ease 0.4s;
  -o-transition: all ease 0.4s;
  -ms-transition: all ease 0.4s;
  transition: all ease 0.4s;
}

.new_case button:hover {
  color: rgb(52, 74, 74);
  background-color: rgb(40, 243, 189);
  box-shadow: 0.5rem 0.5rem 1rem rgba(40, 243, 189, 0.2);
}

.project_info {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  line-clamp: 1;
}

.project_info_active {
  max-height: 15rem;
  overflow-y: auto;
  margin-right: 5px;
  margin-left: 5px;
}
