.neo {
  min-height: 100%;
  background-color: #344a4a;
}
.prodpoco {
  min-height: 100%;
  background-color: #344a4a;
}
.projects_management {
  padding: 0 2rem;
}

/* Start  header */
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2rem 0;
  padding: 2rem 3rem 2rem 1.5rem;
  width: 100%;
}

.header button[type='button'] {
  background-color: rgba(52, 141, 102, 1);
  border: none;
  cursor: pointer;
  width: 100%;
  min-width: 20rem;
  max-width: 25rem;
  margin: 0 auto;
  margin-right: 1rem;
  height: 5rem;
  border-radius: 7px;
  font-family: 'Noto Sans', sans-serif;
  font-size: 1.6rem;
  color: white;
  overflow: hidden;
  outline: none;  

  -webkit-transition: all ease 0.4s;
  -moz-transition: all ease 0.4s;
  -o-transition: all ease 0.4s;
  -ms-transition: all ease 0.4s;
  transition: all ease 0.4s;
}

.header button[type='button']:hover {
  color: rgb(52, 74, 74);
  background-color: rgb(40, 243, 189);
  box-shadow: 0.5rem 0.5rem 1rem rgba(40, 243, 189, 0.2);
}

/* end  header */

/* Start  solver info */
.solver_info {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 29rem;

  margin: 0 auto;
}

.solver_info > svg {
  width: 10rem;
  height: 10rem;
}

.solver_info > div {
  display: flex;
  flex-direction: column;
  margin-left: 0.5rem;
}

.solver_info > div h1 {
  font-size: 3.6rem;
  height: 4.7rem;
  font-weight: 400;
}

.solver_info > div p {
  font-weight: 400;
  height: 2.4rem;
  font-size: 2.4rem;
  text-transform: uppercase;
  color: #78a2a2;
}
/* end  solver info */
