/* simulation_item */
.simulation_item {
  position: relative;
  border-radius: 5px;
  background: transparent;
  outline: none;
  color: #fff;
  border: 1px solid;
  font-size: 1.4rem;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  cursor: pointer;
  text-decoration: none;

  /* min-height: 80px; */
  /* overflow: hidden; */

  transition: filter 200ms ease;
}

.simulation_item:hover {
  filter: drop-shadow(4px 6px 6px rgba(0, 0, 0, 0.5));
}

.simulation_info {
  display: flex;
  flex-direction: row;
  height: 100%;
  line-height: 100%;

  padding: 0.7rem 0.5rem;
  align-items: center;
}

.simulation_info img {
  width: 4rem;
  height: 4rem;
}

.simulation_info button {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  outline: none;
  border: none;
  margin: 3px;
  cursor: pointer;
}

.simulation_info button svg {
  width: 1.8rem;
  height: 1.8rem;
  color: white;
}

.simulation_status {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-top: 1px solid;
  font-weight: bold;
  justify-content: space-between;
  align-items: center;

  text-transform: lowercase;

  padding: 0.1rem 1rem;
}

.simulation_status p::first-letter {
  text-transform: uppercase;
}

.simulation_status .simulation_percentage {
  word-break: keep-all;
}

.blue {
  border-color: #3a48ca;
}

.blue .simulation_status {
  border-color: #3a48ca;
  background-color: #3a48ca;
}

.green {
  border-color: #48b583;
}

.green .simulation_status {
  border-color: #48b583;
  background-color: #48b583;
}

.red {
  border-color: #ff3737;
}

.red .simulation_status {
  border-color: #ff3737;
  background-color: #ff3737;
}

.yellow {
  border-color: #ffb200;
}

.yellow .simulation_status {
  border-color: #ffb200;
  background-color: #ffb200;
}

/* end of simulation_item */
