:root {
  --header-size: 60px;
}

/* header item */
.header_item {
  width: calc(var(--header-size) * 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}
/* end of header item */

/* header icon button */
.header_item_button {
  --button-size: calc(var(--header-size) * 0.6);
  width: var(--button-size);
  height: var(--button-size);
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: 50%;
  padding: 5px;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 300ms ease-in-out;
}
/* end of header icon button */

.header_item_button:hover {
  background-color: rgba(255, 255, 255, 0.15);
}
