.name {
  color: white;
  font-family: 'Noto Sans', sans-serif;
  font-weight: 700;
  font-size: 2.4rem;
  letter-spacing: -0.02px;
  padding: 0 0 0 7rem;
}

.banner {
  position: relative;
  margin: -4rem 0 2rem 6rem;
}

.close-button {
  position: absolute;
  right: 3rem;
  top: 3rem;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
}

.close-button svg {
  width: 4rem;
  height: 4rem;
  color: white;
  transition: transform 0.1s ease-in-out;
  filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
}

.close-button:hover svg {
  transform: scale(1.1);
}

.carousel {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  overflow-x: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

.carousel::-webkit-scrollbar {
  display: none;
}

.container {
  padding: 40px 0;
  display: flex;
  column-gap: 20px;
  transition: left 0.3s ease-in-out;
}

.container > *:not(button) {
  flex-shrink: 0;
}

.left {
  position: sticky;
  left: 0;
}

.right {
  position: sticky;
  right: 0;
}

.container > button {
  z-index: 10;
  height: 220px;
  width: 50px;
  border: none;
  outline: none;
  color: white;
  font-size: 16px;
  background-color: rgba(20, 20, 20, 0.2);
  cursor: pointer;
  opacity: 0;
  transition: all 0.1s ease-in-out;
}

.container > button > svg {
  width: 2.4rem;
  height: 2.4rem;
  color: white;
}

.container:hover > button {
  opacity: 1;
}

.container > button:hover {
  background-color: rgba(20, 20, 20, 0.4);
}

.container > button:hover > svg {
  transform: scale(1.2);
}
