.disciplines-page {
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: center;
  background-color: rgba(24, 34, 34, 1);
  height: 100%;
  padding-top: 5%;
}

.logo-top {
  width: 11rem;
  height: 4rem;
  margin: 0 auto;
}

.subtitle {
  width: max-content;
  margin: 4rem auto;
  font-family: 'Noto Sans', 'Gill Sans', Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1.7rem;
  color: #b5f7ed;
  opacity: 1;
  letter-spacing: 0.23rem;
}

.discipline-list {
  margin: 2.5rem auto;
  max-width: 89rem;
}
