.container {
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 75px);
}

.search_container {
  padding: 1rem;
}
/* simulations */

.simulations {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 0 1rem;

  overflow-y: auto;
}

.simulations::-webkit-scrollbar {
  width: 10px;
  height: 15px;
}
/* end of simulations */
