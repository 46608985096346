.simulations_button {
  position: relative;
  width: 40px;
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
}

.simulations_button:hover {
  background-color: rgb(47, 66, 66);
}

.chart_running_simulation {
  stroke-dasharray: 27;
  stroke-dashoffset: 27;
  stroke: #3cf4bd;

  animation: chart_running 3s ease infinite;
}

@keyframes chart_running {
  30%,
  70% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: -27;
  }
}
